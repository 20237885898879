import axios from 'axios';

export const fetchValidatedStripID = async (stripeID) => {
  try {
    const { data: response } = await axios.get('https://api.manucentric.com/api/Company/ValidateLinkRegister/%STRIPE_CUST_ID'.replace(`%STRIPE_CUST_ID`, stripeID));
    return response;
  } catch (error) {
    return new Error();
  }
};

export const createCompany = async (companyData) => {
  return await axios.post('https://api.manucentric.com/api/Company/InsertCompany', companyData);
};

export const checkCompanySubDomain = async (companySubDomain) => {
  return await axios.post('https://api.manucentric.com/api/Company/CheckCompanyCode', companySubDomain);
};

export const fetchCurrencyList = async () =>{
  try {
    const { data: response } = await axios.get('https://api.manucentric.com/api/Company/Currency');
    return response;
  } catch (error) {
    return new Error();
  }
}

export const getGeocodeAddress = async (payload) =>{
  try {
    const { data: response } = await axios.get('https://maps.googleapis.com/maps/api/geocode/json?',{ params:{ address:payload, key:'AIzaSyDf7bX7ipFzwiYkeCAcVZ68nyYGeY_snSY'}})
    return response;
  } catch (error) {
    return new Error();
  }
}
