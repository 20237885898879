import { Route, Switch } from 'react-router-dom';
import './App.css';
import React from "react";
import 'antd/dist/antd.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import HomePage from "./Pages/HomePage/HomePage";
import CompanyCreationPage from "./Pages/CompanyCreationPage/CompanyCreationPage";
import InvalidUrlPage from "./Pages/InvalidUrlPage/InvalidUrlPage";

const App = () => {
  return (
    <>
      <Switch>
        <Route exact path={'/'} component={HomePage}/>
        <Route exact path={`/company-creation/:userStripeID`} component={CompanyCreationPage} />
        <Route exact path={'/invalidUrl'} component={InvalidUrlPage}/>
      </Switch>
    </>
  );
}

export default App;
