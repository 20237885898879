import React, {useEffect, useState} from "react";
import './CompanyCreationPage.scss';
import CompanyCreationForm from "../../Components/CompanyCreationForm/CompanyCreationForm";
import { useHistory } from 'react-router-dom';
import {Container} from "react-bootstrap";
import { useParams } from 'react-router-dom';
import {fetchValidatedStripID} from "../../service";

const CompanyCreationPage = () => {
  const history = useHistory();
  const params = useParams();
  const userStripeID = params.userStripeID;
  const [isLinkValid, setIsLinkValid] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        const response = await fetchValidatedStripID(userStripeID);
        setIsLinkValid(response.success);
        if(!response.success){
          history.push('/invalidUrl')
        }
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  return (
    <div>
      { isLinkValid ?
        <div>
        <h2 style={{textAlign: 'center'}}>Company Creation</h2>
        <Container>
          <CompanyCreationForm
            stripeCustID = {userStripeID}
          />
        </Container>
      </div> :
        <> </>
      }
    </div>
  );
}

export default CompanyCreationPage;
