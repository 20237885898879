import React, {useEffect, useState} from "react";
import './CompanyCreationForm.scss';
import {useHistory} from 'react-router-dom';
import {useFormik} from 'formik';
import {Button, Form, Input, Upload, message, Row, Col, Modal, Radio, Select} from "antd";
import {checkCompanySubDomain, createCompany, getGeocodeAddress, fetchCurrencyList} from "../../service";
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import SubmitModal from "../Modal/SubmitModal";
import { FormComponentProps } from "antd/lib/form";
const { Option } = Select;

const CompanyCreationForm = (props) => {
  const {stripeCustID} = props;
  const history = useHistory();
  const [isAvailable, setIsAvailable] = useState(false);
  const [logo, setLogo] = useState('');
  const [showSubmitModal, setShowSubmitModal] = useState(false)
  const [dataValue, setDataValue] = useState({})
  const [currencyList, setCurrencyList] = useState([])
  const isValidDomain = require('is-valid-domain')

  useEffect(() => {
    (async () => {
      try {
        const response = await fetchCurrencyList();
        setCurrencyList(response)

      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  const formik = useFormik({
    initialValues: {
      StripeCustID: `${stripeCustID}`,
      CompanyName: '',
      CompanyCode: '',
      LoginBGImage: '',
      Location: '',
      PhoneNumber: ''.toString(),
      Email1: '',
      PrimaryPassword:'',
      SupportPassword:'',
      InchMMValue:'',
      CurrencyValue:''
    },
    onSubmit: (values) => {
      showModal(values)
    },
  });


  const showModal = async(values) => {
    if(!isValidDomain(values.CompanyCode+ '.manucentric.com')){
      const errorUrl = 'https://'+ values.CompanyCode +'.manucentric.com'
      return errorCompanyCode(errorUrl)
    }

    if (isAvailable) {
      const response = await checkCompanySubDomain({CompanyCode:values.CompanyCode});

      if(response.data.success === false || values.CompanyCode === ''){
        setIsAvailable(false);
        return message.error('This Domain Is Not Available!, Please Check Availabilty for Company Subdomain First!')
      }

      if(isAvailable){
        setDataValue(values)
        setShowSubmitModal(!showSubmitModal)
      }
    }else{
      return message.error('Please Check Availabilty for Company Subdomain First!')
    }
  };

  const handleOk = () => {
   setShowSubmitModal(false)
  };

  const handleCancel = () => {
    setShowSubmitModal(false)
  };

  function errorCompanyCode(errorUrl) {
    Modal.error({
      title: 'Company Code (subdomain) Error',
      content: errorUrl +  ' is not a valid URL, please try another company code',
    });
  }

  const checkAvailabilitySubDomain = async (companyCode) => {
    if(!isValidDomain(companyCode.CompanyCode+ '.manucentric.com')){
      const errorUrl = 'https://'+ companyCode.CompanyCode +'.manucentric.com'
      return errorCompanyCode(errorUrl)
    }

    const response = await checkCompanySubDomain({CompanyCode:companyCode.CompanyCode});
    console.log(response.data.success);
    if(response.data.success === false || companyCode.CompanyCode === ''){
      setIsAvailable(false);
      return message.error('This Domain Is Not Available')
    }
    setIsAvailable(true);
    return message.success('This Domain Is Available!')
  }


  // const handleSubmit = async (values) => {
  //   if (isAvailable) {
  //     const response = await checkCompanySubDomain(values);
  //
  //     if(response.data.success === false || values.CompanyCode === ''){
  //       setIsAvailable(false);
  //       return message.error('Please Check Availabilty for Company Subdomain First!')
  //     }
  //
  //     if(isAvailable){
  //       await postCompanyCreationForm(values)
  //     }
  //   }else{
  //     return message.error('Please Check Availabilty for Company Subdomain First!')
  //   }
  // }

  // const postCompanyCreationForm = async (values) => {
  //   try {
  //     await createCompany(values);
  //     message.success('Success Registering Your Company')
  //     return history.push('/');
  //   } catch (err) {
  //     return message.error('Error Registering Your Company')
  //   }
  // };

  const formItemLayout = {
    labelCol: {span: 4},
    wrapperCol: {span: 16},
  };

  const dummyRequest = ({onSuccess}) => {
    setTimeout(() => {
      onSuccess('ok');
    }, 0);
  };

  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  const handleChange = (info) => {
    if (info.file.status === 'uploading') {
      return;
    }
    if (info.file.status === 'done') {
      getBase64(info.file.originFileObj, (logo) => {
        const base64Cropped = logo.split(',')[1];
        setLogo(logo);
        formik.setFieldValue('LoginBGImage', base64Cropped);
      });
    }
  };

  const uploadButton = (
    <div>
      <div style={{marginTop: 8}}>Logo</div>
    </div>
  );

  const validateMessages = {
    required: '${label} is required!',
    types: {
      email: '${label} is not a valid email!',
      number: '${label} is not a valid number!',
    },
    number: {
      range: '${label} must be between ${min} and ${max}',
    },
  };

  const getGeocodeByAddress = async(address) =>{
    const getAddress = await getGeocodeAddress(address)
    return getAddress;
  }

  const getAddress = async (event) => {
    if (event.label !== null && event.label !== undefined) {
      let geocodeAddress = await getGeocodeByAddress(event.label)


      let fullAddress = geocodeAddress.results[0].address_components
      let streetAddress = ''
      let route = '';
      let subpremise=''
      let streetNumber = ''
      let city = ''
      let province = ''
      let country = ''
      let postalCode = ''
      fullAddress.map(value => {
        if(value.types[0] == 'administrative_area_level_2'){
          city = value.long_name
        }

        if(value.types[0] == 'administrative_area_level_1'){
          province = value.long_name
        }

        if(value.types[0] == 'country'){
          country = value.long_name
        }

        if(value.types[0] == 'postal_code'){
          postalCode = value.long_name
        }

        if(value.types[0] == 'route'){
          route = value.long_name
        }

        if(value.types[0] == 'subpremise'){
          subpremise = value.long_name
        }

        if(value.types[0] == 'street_number'){
          streetNumber = value.long_name
        }

      })

      streetAddress = route + ' ' + subpremise + ' ' + streetNumber

      if(country != ''){
        streetAddress += ',' + country
      }

      if(province != ''){
        streetAddress += ',' + province
      }

      if(city != ''){
        streetAddress += ',' + city
      }

      if(postalCode != ''){
        streetAddress += ',' + postalCode
      }

      await formik.setFieldValue('Location', streetAddress)
    }
  };

  const handleChangeCurrency = (value) =>{
    formik.setFieldValue('CurrencyValue', value)
  }

  return (
    <div className='company-creation-form'>
      <Form {...formItemLayout} onFinish={formik.handleSubmit} validateMessages={validateMessages}>
        <Form.Item label='Company Name' name='CompanyName'>
          <Input
            name='CompanyName'
            placeholder='insert company name here'
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
          />
        </Form.Item>

        <Form.Item label='Preferred Subdomain Name' name='CompanyCode'>
          <Row>
            <Col span={8}>
              <Input
              name='CompanyCode'
              placeholder='insert preferred subdomain here'
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
            />
            </Col>
            <Col span={8}>
              <Button type='primary' onClick={()=> checkAvailabilitySubDomain(formik.values)}>
              Check Domain Availability
            </Button>
            </Col>
          </Row>

        </Form.Item>

        <Form.Item label='Logo' name='LoginBGImage'>
          <Upload
            name='LoginBGImage'
            listType='picture-card'
            showUploadList={false}
            customRequest={dummyRequest}
            onChange={handleChange}
          >
            {formik.values.LoginBGImage ? (
              <img
                src={logo}
                alt='example'
                style={{width: '100%', height: '100%'}}
              />
            ) : (
              uploadButton
            )}
          </Upload>
        </Form.Item>

        <Form.Item label='Address'>
          <GooglePlacesAutocomplete
            apiKey="AIzaSyATGN9H5NvDbT3VaEstp5ayrzHxvP2cCto"
            selectProps={{
              onChange: (e)=> getAddress(e)
            }}
          />
        </Form.Item>

        <Form.Item label='Phone Number' name='PhoneNumber'>
          <Input
            name='PhoneNumber'
            type="number"
            placeholder='insert phone number here'
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
          />
        </Form.Item>

        <Form.Item
          name="CurrencyPreference"
          label="Currency Preference"
          rules={[{ required: true, message: 'Please Select Your Currency Preference' }]}
        >
          <Select placeholder="select your currency preference" name ='CurrencyValue' onBlur={formik.handleBlur} onChange={(e)=>handleChangeCurrency(e)}>
            {currencyList.length > 0 ?  currencyList.map((currency) => {
             return <Option value={currency.currencySymbol} >{currency.currencyName}</Option>
            }) : ''}
          </Select>
        </Form.Item>

        <Form.Item
          name="InchMMValue"
          label="Measurement Preference"
          rules={[{ required: true, message: 'Please pick your measurement preference item!' }]}

        >
          <Radio.Group name ='InchMMValue' onBlur={formik.handleBlur} onChange={formik.handleChange}>
            <Radio.Button value="inch">inch</Radio.Button>
            <Radio.Button value="mm">mm</Radio.Button>
          </Radio.Group>
        </Form.Item>

        {/*<Form.Item label='Support Email' name='SupportEmail' rules={[{ type: 'email', required: true}]}>*/}
        {/*  <Input*/}
        {/*    name='SupportEmail'*/}
        {/*    placeholder='insert support email here'*/}
        {/*    onBlur={formik.handleBlur}*/}
        {/*    onChange={formik.handleChange}*/}
        {/*  />*/}
        {/*</Form.Item>*/}

        <Form.Item label='Primary Email' name='Email1' rules={[{ type: 'email', required: true}]}>
          <Input
            name='Email1'
            placeholder='insert primary email here'
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
          />
        </Form.Item>


        <Form.Item style={{justifyContent: 'center'}}>
          <Button type='primary' htmlType='submit'>
            Submit
          </Button>
        </Form.Item>
      </Form>


      <SubmitModal
        visible={showSubmitModal}
        handleOk={handleOk}
        handleCancel={handleCancel}
        data={dataValue}
        formik={formik}
        validateMessage={validateMessages}
      />
    </div>
  );
}

export default CompanyCreationForm;