import React, {useEffect, useState} from "react";
import {Button, Col, Form, Input, message, Modal, Row, Upload} from "antd";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import {createCompany} from "../../service";
import {useHistory} from 'react-router-dom';
import { validateYupSchema } from "formik";

const SubmitModal = (props) => {
  const {visible, handleOk, handleCancel,data,formik,validateMessage, postCompanyCreation} = props
  const [password, setPassword] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const history = useHistory();

  useEffect(() => {
    (async () => {

    })();
  }, [data]);

  const postCompanyCreationForm = async (values) => {
    setIsLoading(true)
    values.PrimaryPassword = formik.values.PrimaryPassword;
    values.PhoneNumber = formik.values.PhoneNumber.toString();

    if(formik.values.PrimaryPassword === ''){
      setIsLoading(false)
      return message.error('Password Could Not Be Empty')
    }

    setIsLoading(true)
    try {
      await createCompany(values);
      setIsLoading(false)
      await countDown()
    } catch (err) {
      setIsLoading(false)
      return message.error('Error Registering Your Company')
    }
  };

  function countDown() {
    let secondsToGo = 3;
    const modal = Modal.success({
      title: 'Success Registering Company',
    });
    const timer = setInterval(() => {
      secondsToGo -= 1;
      modal.update({
        content: `Redirecting you to https://${data.CompanyCode}.manucentric.com in ${secondsToGo} seconds...`,
        width: '400px'
      });
    }, 1000);
    setTimeout(() => {
      clearInterval(timer);
      modal.destroy();
      window.location.replace(`https://${data.CompanyCode}.manucentric.com`)
    }, secondsToGo * 1000);
  }

  return (
    <div>
      <Modal
        visible={visible}
        title="Submit Company Creation"
        width={'1000px'}
        footer={[
          <Button key="back" loading={isLoading} onClick={()=> handleCancel()}>
            Return
          </Button>,
          <Button key="submit"  loading={isLoading} type="primary" onClick={()=> postCompanyCreationForm(data)}>
            Submit
          </Button>,
        ]}
      >
        <div>
          <p>We will create <b>https://{data.CompanyCode}.manucentric.com</b> for you, and the primary email will be assigned as Super Admin.</p>
          <Form onFinish={formik.handleSubmit} validateMessages={validateMessage}>
            <Form.Item label={'password'} name='PrimaryPassword' rules={[{required: true}]}>
              <Input
                name='PrimaryPassword'
                placeholder={`Please key-in a password [${data.Email1}]`}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                type={'password'}
              />
            </Form.Item>
          </Form>
        </div>
      </Modal>
    </div>
  );
}

export default SubmitModal;
