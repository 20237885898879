import React from "react";
import './InvalidUrlPage.scss';
import {Container} from "react-bootstrap";

const InvalidUrlPage = () => {
  return (
    <div>
      <Container>
        <h1 style={{textAlign: "center"}}>Invalid Url</h1>
      </Container>
    </div>
  );
}

export default InvalidUrlPage;
