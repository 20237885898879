import React from "react";
import './HomePage.scss';
import {Container} from "react-bootstrap";

const HomePage = () => {
  return (
    <div>
      <Container>
        <h1 style={{textAlign: 'center'}}>HomePage</h1>
      </Container>
    </div>
  );
}

export default HomePage;
